export default () => ({
    isPWA : false,
    toastr: { type: '', message: '' },
    isSearchResultActive: 0,
    loading: false,
    loadingLoadMore: false,
    searchProgressStatus: '',
    bulkSelectedRecords: [],
    searchPageUrl: "",
    isLiteSubscription: false,
    isSavedSearch: false,
    canReset: false,
    page: 1,
    recordsPerPage: 15,
    showToolbar: true,
    showFilterSort: true,
    orderBy: '0',
    searchTab: 'search.standards',
    activeCollectionId: 0,
    activeCollectionName: '',
    fromCollection: 0,
    actions: [],
    summary: "",
    searchTerms: "",
    initialQuery: {},
    "allRecords": [],
    "records": [],
    "hits": 0,
    "total": 0,
    "docIds": [],
    "facets": {},
    "cached": false,
    "filters": {},
    exportStatus: '',
    selectedfieldsForExport: [
        'docId', 'name', 'title', 'status', 'language', 'publisher', 'country', 'latestVersion', 'versionDate', 'summary'
    ],
    exportResult: {},
    exportCsvSeparator: 'csv',
    searchMain: false
});
