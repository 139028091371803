import Mutation from './mutationTypes';
import initialState from './initialState';
import {
    apiSendPromptToAI,
    apiLoadChatHistory,
    apiDeleteChatHistory,
    apiGenerateChatTitle,
    apiRateChatMessage,
    apiUpdateChatTitle,
    apiLoadChatMessages,
    apiGetChatQueryCount,
    apiGetAllFaqForDoc,
    apiSearchChatHistory
} from "@/components/AIChat/api"

export default {
    setItem: ({ commit }, {field, value}) => {
        commit(Mutation.SET_ITEM, {field, value});
    },
    sendPromptToAI: async ({ commit, state, getters, dispatch }, message) => {
        try {
            let params = {
                docId: state.docId,
                itemId: state.itemId,
                chatId: state.chatId,
                resId: state.resId,
                pubId: state.pubId,
                versionId: state.versionId,
                message
            };
            commit(Mutation.ADD_TO_CHAT_MESSAGE, {role: 'user', messageId: '', content: message, citations: [] });
            commit(Mutation.SET_ITEM, {field: 'waitingReply', value: true});
            const reply =  await apiSendPromptToAI(params);
            if(reply) {
                let role = 'assistant';
                if(reply.aiMessage === 'subscription_chat_limit_exceeded') {
                    role = 'info';
                }
                if(reply.aiMessage === 'openai_could_not_process_request') {
                    role = 'info';
                }
                dispatch('updateQuotaUsed'); //update quota used 
                commit(Mutation.ADD_TO_CHAT_MESSAGE, {role, messageId: reply.messageId, content: reply.aiMessage, citations: reply.citations });
                commit(Mutation.SET_ITEM, {field: 'waitingReply', value: false});
                if(reply.content !== 'subscription_chat_limit_exceeded') {
                    const stateChatId = state.chatId    
                    commit(Mutation.SET_ITEM, {field: 'chatId', value: reply.chatId});
                    //if(!chatObjExists) { //new chat
                    if( stateChatId == '') {//new chat
                        let tmpChatTitle = reply.aiMessage.substr(0, 30);
                        state.chatMessages.forEach(item => {
                            item.chat_id = reply.chatId;
                        });
                        let newChatItem = {
                            id: reply.chatId,
                            item_id: state.itemId,
                            doc_id: state.docId,
                            res_id: state.resId,
                            pub_id: state.pubId,
                            version_id: state.versionId,
                            created_at: reply.response_at,
                            udpated_at: reply.response_at,
                            title_tmp: tmpChatTitle,
                            title: null,
                            messages:  state.chatMessages
                        };
                        commit(Mutation.LOAD_CHAT_HISTORY_ITEMS, {isNew: true, chatItems: [newChatItem]});
                    }
                    else {
                        let chatObjExists = getters.getChatHistoryItem(reply.chatId);
                        if(state.chatMessages.length > 3 && !chatObjExists.title) {
                            let chatTitle =  await apiGenerateChatTitle(reply.chatId);
                            commit(Mutation.UPDATE_CHAT_TITLE, { chatId: reply.chatId, title: chatTitle })
                        } 
                    }
                }
            } else {
                commit(Mutation.ADD_TO_CHAT_MESSAGE, {role: 'info', messageId: reply.messageId, content: 'openai_could_not_process_request', citations: [] });  
                commit(Mutation.SET_ITEM, {field: 'waitingReply', value: false});  
            }
            
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'waitingReply', value: false});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },
    loadChatHistory: async ({ commit, state }, filters) => {
        try {
            let chatHistory = state.chatHistory;
            if(chatHistory.length == 0) {
                filters = {...filters, offset: 0, limit: state.chatHistoryLimit};
                commit(Mutation.SET_ITEM, {field: 'loadChatHistoryProcessing', value: true});
                chatHistory = await apiLoadChatHistory(filters);
                if(chatHistory.length > 0) {
                    commit(Mutation.LOAD_CHAT_HISTORY_ITEMS, {isNew: false, chatItems: chatHistory});
                    commit(Mutation.SET_ITEM,  {field: 'chatHistoryOffset', value: state.chatHistoryOffset + state.chatHistoryLimit});
                } else {
                    commit(Mutation.SET_ITEM,  {field: 'noChatHistroy', value: true});
                }
                
                chatHistory = state.chatHistory;
                commit(Mutation.SET_ITEM, {field: 'loadChatHistoryProcessing', value: false});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loadChatHistoryProcessing', value: false});
            console.log('api call failed for chat history');
            console.log(error);
        }
    },
    loadMoreChatHistory: async ({ commit, state }) => {
        try {
            let filters = {
                docId: state.docId, 
                // userId: state.userId, 
                // subId: state.subId, 
                offset: state.chatHistoryOffset,
                limit: state.chatHistoryLimit
            };
            commit(Mutation.SET_ITEM, {field: 'loadChatHistoryProcessing', value: true});
            let chatHistory = await apiLoadChatHistory(filters);
            if(chatHistory.length > 0) {
                commit(Mutation.LOAD_CHAT_HISTORY_ITEMS, {isNew: false, chatItems: chatHistory});
                const newOffset = state.chatHistoryOffset + state.chatHistoryLimit;
                commit(Mutation.SET_ITEM,  {field: 'chatHistoryOffset', value: newOffset });
            } else {
                commit(Mutation.SET_ITEM,  {field: 'noChatHistroy', value: true});
            }
            chatHistory = state.chatHistory;
            commit(Mutation.SET_ITEM, {field: 'loadChatHistoryProcessing', value: false});
          
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loadChatHistoryProcessing', value: false});
            console.log('api call failed for load ore chat history');
            console.log(error);
        }
    },
    initChatState: ({ commit }) => {
        for (const key in initialState) {
            commit(Mutation.SET_ITEM, {field: key, value: initialState[key]});    
        }
    },
    initChat: async ({ commit }, chatDetails) => {
        for (const key in chatDetails) {
            commit(Mutation.SET_ITEM, {field: key, value: chatDetails[key]});    
        }
        commit(Mutation.SET_ITEM, {field: 'showMainChatWindow', value: true});    
    },
    closeChat:  ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showMainChatWindow', value: false});    
    },
    deleteChatHistory: async ({ state, commit, dispatch }, chatId) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'deleteChatItemLoading', value: true});
            const deletedSuccess =  await apiDeleteChatHistory(chatId);
            if(deletedSuccess) {
                if(chatId ==  state.chatId) {
                    dispatch('setUpNewChat');
                }
                commit(Mutation.REMOVE_CHAT_ITEM, chatId);
                commit(Mutation.SET_ITEM, {field: 'deleteChatItemLoading', value: false});
            } else {
                commit(Mutation.SET_ITEM, {field: 'deleteChatItemLoading', value: false});
                console.log('delete failed');
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'deleteChatItemLoading', value: false});
            console.log('api call failed for deleting chat history');
            console.log(error);
        }  
    }, 
    updateChatTitle: async ({ commit }, {chatId, originalTitle, title}) => {
        try {
            commit(Mutation.UPDATE_CHAT_TITLE,  {chatId, title});
            const renameSuccess =  await apiUpdateChatTitle(chatId, title);
            if(!renameSuccess) {
                commit(Mutation.UPDATE_CHAT_TITLE,  {chatId, originalTitle});
                console.log('update chat title failed');
            } 
        } catch(error) {
            console.log('api call failed for update chat title');
            console.log(error);
        }  
    }, 
    rateChatMessage: async ({ commit, state }, { messageId, rating}) => {
        try {
            const rateSuccess =  await apiRateChatMessage(state.chatId, messageId, rating);
            if(rateSuccess) {
                commit(Mutation.SET_CHAT_MESSAGE_RATING, { messageId, rating });
            } else {
                console.log('chat message rating failed');
            }
        } catch(error) {
            console.log('api call failed for rating chat message');
            console.log(error);
        }  
    }, 
    setUpNewChat: async ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showSearchHistory', value: false});
        commit(Mutation.SET_ITEM, {field: 'showVersionOptions', value: false});
        commit(Mutation.SET_ITEM, {field: 'waitingReply', value: false});
        commit(Mutation.SET_ITEM, {field: 'chatMessages', value: []});
        commit(Mutation.SET_ITEM, {field: 'userMessage', value: ''});
        commit(Mutation.SET_ITEM, {field: 'loading', value: false});
        commit(Mutation.SET_ITEM, {field: 'itemId', value: ''}); 
        commit(Mutation.SET_ITEM, {field: 'chatId', value: ''});
        commit(Mutation.SET_ITEM, {field: 'resId', value: ''}); 
        commit(Mutation.SET_ITEM, {field: 'pubId', value: ''});
        commit(Mutation.SET_ITEM, {field: 'loadMoreMsgsProcessing', value: false});
        commit(Mutation.SET_ITEM, {field: 'loadMoreChatProcessing', value: false});
        commit(Mutation.SET_ITEM, {field: 'searchChatHistoryProcessing', value: false});
        commit(Mutation.SET_ITEM, {field: 'faq', value: ''});
        commit(Mutation.SET_ITEM, {field: 'wizardStep', value: 'version-selection'});
        commit(Mutation.SET_ITEM, {field: 'versionId', value: ''});
        commit(Mutation.SET_ITEM, {field: 'versionName', value: ''});
    },

    loadChatFromHistory: async ({ commit, dispatch, state }, chat) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'loadChatMsgsProcessing', value: true});
            dispatch('setUpNewChat');
            commit(Mutation.SET_ITEM, {field: 'chatId', value: chat.id}); 
            commit(Mutation.SET_ITEM, {field: 'itemId', value: chat.item_id}); 
            commit(Mutation.SET_ITEM, {field: 'resId', value: chat.res_id}); 
            commit(Mutation.SET_ITEM, {field: 'pubId', value: chat.pub_id}); 
            commit(Mutation.SET_ITEM, {field: 'itemId', value: chat.item_id}); 
            commit(Mutation.SET_ITEM, {field: 'versionId', value: chat.version_id}); 
            commit(Mutation.SET_ITEM, {field: 'wizardStep', value: 'chat-messaging'}); 
            if (typeof chat.messages !== 'undefined' && 
                Array.isArray(chat.messages) && 
                chat.messages.length > 0) {
                commit(Mutation.LOAD_CHAT_MESSAGES, chat.messages);
            }
            else {
                let chatMessages = await apiLoadChatMessages(chat.id, 0, state.chatMessageLimit);
                if(chatMessages.length > 0) {
                    commit(Mutation.LOAD_CHAT_MESSAGES, chatMessages);
                    commit(Mutation.SET_ITEM, { field: 'chatMesageOffset', value: state.chatMessageLimit });
                } else {
                    commit(Mutation.LOAD_CHAT_MESSAGES, []);
                }
            }
            commit(Mutation.SET_ITEM, {field: 'loadChatMsgsProcessing', value: false});
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loadChatMsgsProcessing', value: false});
            console.log('loading chat item from history failed');
            console.log(error);
        }  
    },
    loadMoreMessages: async ({ commit, state }, chatId) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'loadMoreMsgsProcessing', value: true});
            let moreChatMessages = await apiLoadChatMessages(chatId,  state.chatMesageOffset, state.chatMessageLimit);
            if(moreChatMessages.length > 0) {
                commit(Mutation.LOAD_CHAT_MESSAGES, moreChatMessages);
                const newOffset = state.chatMesageOffset + state.chatMessageLimit ;
                commit(Mutation.SET_ITEM, { field: 'chatMesageOffset', value: newOffset});
                commit(Mutation.SET_ITEM, {field: 'loadMoreMsgsProcessing', value: false});
            } else {
                commit(Mutation.SET_ITEM, {field: 'loadMoreMsgsProcessing', value: false});
                console.log('loading more messages failed');
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loadMoreMsgsProcessing', value: false});
            console.log('api call failed while loading more messages');
            console.log(error);
        }  
    },
    updateQuotaUsed: async ({ commit }) => {
        try {
            let quotaUsed = await apiGetChatQueryCount();
            quotaUsed = isNaN(parseInt(quotaUsed, 10)) ? 0 : parseInt(quotaUsed, 10);
            commit(Mutation.SET_ITEM, {field: 'quotaUsed', value: quotaUsed});
        } catch(error) {
            console.log('api call to get quota used failed');
            console.log(error);
        }  
    },
    loadDocFaqs: async ({ commit, state }) => {
        try {
            let faqs = state.faqs;
            if(Object.keys(faqs).length == 0 && !state.faqFetched) {
                commit(Mutation.SET_ITEM, {field: 'loadFaqProcessing', value: true});
                let faqs = await apiGetAllFaqForDoc(state.docId);
                if(faqs && Object.keys(faqs).length > 0) {
                    commit(Mutation.SET_ITEM, {field: 'faqs', value: faqs});
                } 
                commit(Mutation.SET_ITEM, {field: 'faqFetched', value: true});
                commit(Mutation.SET_ITEM, {field: 'loadFaqProcessing', value: false});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loadFaqProcessing', value: false});
            console.log('api call failed while loading faq');
            console.log(error);
        }  
    },
    searchChatHistory: async ({ commit, getters }, searchTerm) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'searchChatHistoryProcessing', value: true});
            let searchHistoryAbortController = getters.getItem('ajaxAbortController.apiSearchChatHistory');
            if(searchHistoryAbortController) {
                searchHistoryAbortController.abort(); //cancel previous ajax request
            }
            searchHistoryAbortController =  new AbortController();
            commit(Mutation.SET_ITEM, {field: 'ajaxAbortController.apiSearchChatHistory', value: searchHistoryAbortController});
            const searchResult = await apiSearchChatHistory(searchTerm, searchHistoryAbortController);
            if(!searchResult.canceled) {
                commit(Mutation.SET_ITEM, {field: 'searchResult', value:searchResult.data});
                commit(Mutation.SET_ITEM, {field: 'searchChatHistoryProcessing', value: false});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'searchChatHistoryProcessing', value: false});
            console.log('api call failed for chat history');
            console.log(error);
        }
    },
    
    
}