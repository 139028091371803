import Mutation from '@/components/AIChat/store/mutationTypes';

const addChatSideEffects = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("aichat/" + Mutation.SET_ADD_CHAT_MESSAGE)) {
            setTimeout(function() { $(".cb-chatarea").scrollTop($(".cb-chatarea")[0].scrollHeight) ; }, 200);
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM) && mutation.payload.field == 'waitingReply') {
            if($(".cb-chatarea")) {
                setTimeout(function() { $(".cb-chatarea").scrollTop($(".cb-chatarea")[0].scrollHeight) ; }, 200);
            }
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM)) {
            if( mutation.payload.field == 'userMessage') {
                //give some time to set the message in textarea, before we calculate the height
                setTimeout(function() { 
                    $("textarea#usermessage").height(22);
                    if(mutation.payload.value!='') {
                        $("textarea#usermessage").height($("#usermessage").prop('scrollHeight')+2);
                    }
                },20);
            }
            if(mutation.payload.field == 'waitingReply' && mutation.payload.value==false) {
                setTimeout(function() { $("textarea#usermessage").focus();},20);
            }
        }
    });
}

export default [
    addChatSideEffects
];