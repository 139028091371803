import Mutation from './mutationTypes';

export default {
    [Mutation.SET_ITEM]: (state, { field, value }) => {
        const keys =  field.split(".");
        let currentLevel = state;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            
            // If the key doesn't exist at the current level, create an empty object
            if (!Object.prototype.hasOwnProperty.call(currentLevel, key)) {
                currentLevel[key] = {};
            }
            
            // If it's the last key, set the value
            if (i === keys.length - 1) {
                currentLevel[key] = value;
            }
            
            // Move the current level deeper into the nested structure
            currentLevel = currentLevel[key];
        }
    }, 
    [Mutation.ADD_TO_CHAT_MESSAGE]: (state, { messageId, role, content, citations }) => {
        state['chatMessages'].push({ id: messageId, role, content, citations, rating: '' });
    },
    [Mutation.LOAD_CHAT_MESSAGES]: (state, messages) => {
        state['chatMessages'] = messages.concat(state['chatMessages']);
    },
    [Mutation.SET_CHAT_MESSAGE_RATING]: (state, { messageId, rating }) => {
        state['chatMessages'].forEach((item, index) => {
            if(item.id.toString() == messageId.toString()) {
                state['chatMessages'][index]['rating'] = rating;
            }
        });
    },
    // [Mutation.ADD_CHAT_ITEM_TO_CHAT_HISTORY]: (state, chatItem) => {
    //     state['chatHistory'].unshift(chatItem);
    // },
    [Mutation.REMOVE_CHAT_ITEM]: (state, chatId) => {
        let chatHistories = Object.fromEntries(
            Object.entries(state['chatHistory']).map(([key, items]) => [
                key,
                Array.isArray(items)
                    ? items.filter(chat => chat.id.toString() !== chatId.toString())
                    : items // Keep as is if not an array
            ])
        );
        state['chatHistory'] = chatHistories;
    },
    [Mutation.UPDATE_CHAT_TITLE]: (state, { chatId, title }) => {
        let chatHistories = Object.fromEntries(
            Object.entries(state['chatHistory']).map(([key, items]) => [
                key,
                Array.isArray(items)
                    ? items.map(chat => ({
                        ...chat,
                        title: chat.id.toString() === chatId.toString() ? title : chat.title
                    }))
                    : items // Keep as is if not an array
            ])
        );
        state['chatHistory'] =  { ...chatHistories };
    },
    [Mutation.LOAD_MESSAGES_TO_CHAT_HISTORY_ITEM]: (state, { chatId, messages }) => {
        let chatHistories = Object.fromEntries(
            Object.entries(state['chatHistory']).map(([key, items]) => [
                key,
                Array.isArray(items)
                    ? items.map(chat => ({
                        ...chat,
                        messages: chat.id.toString() === chatId.toString() ? messages : []
                    }))
                    : items // Keep as is if not an array
            ])
        );
        state['chatHistory'] = chatHistories;
    },
    [Mutation.LOAD_CHAT_HISTORY_ITEMS]: (state, {isNew , chatItems}) => {
        const now = new Date();
        const todayStr = now.toLocaleDateString();  // Local date format (YYYY-MM-DD)
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = yesterday.toLocaleDateString();  // Local date format (YYYY-MM-DD)
    
        const previous7days = new Date(now);
        previous7days.setDate(previous7days.getDate() - 7);
    
        const previous30days = new Date(now);
        previous30days.setDate(previous30days.getDate() - 30);
    
        chatItems.forEach(item => {
            // Normalize the created_at field, ensure it's treated as UTC
            let createdAt = item.created_at;
            
            // If 'created_at' is in 'YYYY-MM-DD HH:mm:ss' format, append 'Z' to treat it as UTC
            if (createdAt && !createdAt.includes('T')) {
                createdAt = createdAt.replace(' ', 'T') + 'Z';  // Convert to ISO 8601 with 'Z'
            }
    
            const updatedDate = new Date(createdAt);
            const updatedStr = updatedDate.toLocaleDateString();  // Local date format (YYYY-MM-DD)
            
            let categoryKey = "";
    
            if (updatedStr === todayStr) {
                categoryKey = "today";
            } else if (updatedStr === yesterdayStr) {
                categoryKey = "yesterday";
            } else if (updatedDate >= previous7days) {
                categoryKey = "previous7days";
            } else if (updatedDate >= previous30days) {
                categoryKey = "previous30days";
            } else {
                const year = updatedDate.getFullYear();
                const month = updatedDate.toLocaleString('default', { month: 'long' });
                const monthYearCategory = `${month}`;
    
                if (year === now.getFullYear()) {
                    categoryKey = monthYearCategory; // Categorize by month in the current year
                } else {
                    categoryKey = `${year}`; // Group older years separately
                }
            }
            
            // Ensure the category exists
            if (!state.chatHistory[categoryKey]) {
                if(categoryKey == 'today') { //move it to the top
                    state.chatHistory = {
                        today: [],
                        ...state.chatHistory
                    };
                } else {
                    state.chatHistory[categoryKey] = [];
                }
            }
    
            // Append new chatItems (prevent duplicates if needed)
            const exists = state.chatHistory[categoryKey].some(existingItem => existingItem.id === item.id);
            if (!exists) {
                if(isNew) {
                    state.chatHistory[categoryKey].unshift(item);
                } else {
                    state.chatHistory[categoryKey].push(item);
                }
                state['chatHistory'] = { ...state.chatHistory };
            }
        });
    }
    
}