export default () => ({
    availableItems: {},
    toc1: [],
    toc2: [],
    itemId1: '',
    itemId2: '',
    itemName1: '',
    itemName2: '',
    toc1Selection: {},
    toc2Selection: {},
    promptOptions: [],
    selectedPromptId: 0,
    diffSummary: false,
    diffSummaryId: 0,
    selectedRating: -1,
    updateRatingInProgress: false,
    language: '',
    docName: '',
    docId: '',
    loading: false,
    compareVersionsError: false,
    showCompareWindow: false,
    abortController: {},
    processAborted: false,
    queryLimitExceeded: false,
    activeTab: '',
    tabPage: '',
    promptOptionsLoading: false,
    quotaUsed: 0,
    quotaAvailable: 0,
    prevActiveTabs: [],
    compareHistory: [
        {
            id: '',
            date: '',
            version1: '',
            version2: '',
        }
    ],
    compareHistoryPage: 1,
    compareHistoryItemsPerPage: 10,
    compareHistorySortBy: 'date',
    compareHistorySortOrder: 'desc',
    compareHistoryTotal: 0,
    compareHistorySortDesc: false,
    compareHistoryDateRange: ['', ''],
    compareHistoryPriorVersion: '',
    compareHistoryNewerVersion: '',
    compareHistoryLoading: false,
    compareParams: {},
    showConfirmDeleteModal: false,
    deleteItemId: '',
    newCompare: false,
    compareSummaryLoading: false
});
