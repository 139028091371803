import Mutation from './mutationTypes';
import { apiGetSecitonDiffSummary, apiLoadPromptOptions, apiUpdateSummaryRating, apiCheckDiffSummaryStatus, apiGetDiffSummaryByJobId, apiGetCompareHistory, apiDeleteCompareHistory, apiGetCompareQueryCount  } from "@/components/AICompare/api"

export default {
    setItem: ({ commit }, {field, value}) => {
        commit(Mutation.SET_ITEM, {field, value});
    },
    initComparePDF: async ({ commit, dispatch  }, compareDetails) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'showCompareWindow', value: true});
            commit(Mutation.SET_ITEM, {field: 'toc1Selection', value: {}});
            commit(Mutation.SET_ITEM, {field: 'toc2Selection', value: {}});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: ''});
            commit(Mutation.SET_ITEM, {field: 'diffSummaryId', value: 0});
            commit(Mutation.SET_ITEM, {field: 'selectedRating', value: -1});
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            commit(Mutation.SET_ITEM, {field: 'initLoading', value: false});
            commit(Mutation.SET_ITEM, {field: 'prevActiveTabs', value: []});
            commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: false});
            for (const key in compareDetails) {
                commit(Mutation.SET_ITEM, {field: key, value: compareDetails[key]});
            }
            commit(Mutation.SET_ITEM, {field: 'availableItems', value: compareDetails.availableItems});
            dispatch('setTOCForCompare', {versionType: 'prior', itemId: compareDetails.itemId1, itemName: compareDetails.itemName1});
            dispatch('setTOCForCompare', {versionType: 'new', itemId: compareDetails.itemId2, itemName: compareDetails.itemName2});
            dispatch('setActivePage', {activeTab: 'compare-versions', tabPage: 'compare-toc'});
        } catch(error) {
            console.log('api call failed for getting previous chat list');
            console.log(error);
        }
    },
    loadComparePromptOptions: async ({ commit, state }) => {
        try {
            let promptOptions = state.promptOptions;
            if(promptOptions.length == 0) {
                commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: true});
                const promptOptions =  await apiLoadPromptOptions();
                if(promptOptions.prompts) {
                    promptOptions.prompts.unshift({id: 0, title: 'Select response format...'});
                    commit(Mutation.SET_ITEM, {field: 'promptOptions', value: promptOptions.prompts});
                }
                commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: false});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: false});
            console.log('failed to load prompt options');
            console.log(error);
        }
    },
    getSecitonDiffSummary: async ({ commit, getters, dispatch }, compareParams) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'loading', value: true});
            commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: true});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: ''});
            let compareApiAbortController =  new AbortController();
            commit(Mutation.SET_ITEM, {field: 'abortController.compareApi', value: compareApiAbortController});
            const { jobId, limitError} =  await apiGetSecitonDiffSummary(compareParams, compareApiAbortController);
            const updateStateForError = () => {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: true});
                commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
            }
            if(jobId) {
                //check if diff available every 5 sec
                let count = 0;
                let checkDiffSummaryStatusCallback = async function() {
                    if(getters.getItem('processAborted')) { //if the compare has been canceled by click back or close button do nothing.
                        return;
                    }
                    count++;
                    if(count > 15) {
                        updateStateForError();
                        return ;
                    }
                    let checkDiffStatusApiAbortController =  new AbortController();
                    commit(Mutation.SET_ITEM, {field: 'abortController.checkDiffStatusApi', value: checkDiffStatusApiAbortController});
                    const response = await apiCheckDiffSummaryStatus(jobId, checkDiffStatusApiAbortController);
                    if(response.status) {
                        switch(response.status) {
                            case 'PENDING':
                            case 'PROCESSING':
                                setTimeout(checkDiffSummaryStatusCallback, 5000);
                                break;
                            case 'FINISHED': {
                                try {
                                    const response = await apiGetDiffSummaryByJobId(jobId);
                                    if(response && response.aiMessage && response.id) {
                                        //when finished, call the api to save the response
                                        commit(Mutation.SET_ITEM, {field: 'diffSummary', value: response.aiMessage});
                                        commit(Mutation.SET_ITEM, {field: 'diffSummaryId', value: response.id});
                                        dispatch('setActivePage', {activeTab: 'compare-versions', tabPage: 'compare-summary'});
                                        commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                                        commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: false});
                                        commit(Mutation.SET_ITEM, {field: 'newCompare', value: true});
                                        dispatch('updateQuotaUsed'); //update quota used
                                    } else {
                                        updateStateForError();
                                    }
                                } catch(error) {
                                    commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                                    commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: false});
                                    commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
                                    console.log(error);
                                }
                                break;
                            }
                            case 'ERROR':
                            default:
                                updateStateForError();
                            break;
                        }
                    }
                }
                setTimeout(checkDiffSummaryStatusCallback, 10000);
            } else if(limitError === 'subscription_compare_limit_exceeded')  {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'query_limit_exceeded'});
            } else {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            commit(Mutation.SET_ITEM, {field: 'compareSummaryLoading', value: false});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },
    updateSummaryRating: async ({ commit },{ summaryId, rating }) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: ''});
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: true});
            await apiUpdateSummaryRating({ summaryId, rating });
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: false});
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: false});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_rate'});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },
    closeCompare:  ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showCompareWindow', value: false});
    },
    abortApiCall: ({ getters }) => {
        let checkDiffStatusApi = getters.getItem('abortController.checkDiffStatusApi');
        if(checkDiffStatusApi) {
            checkDiffStatusApi.abort();
        }
        let compareApi = getters.getItem('abortController.compareApi');
        if(compareApi) {
            compareApi.abort();
        }
        let loadTOCApi = getters.getItem('abortController.loadTOCApi');
        if(loadTOCApi) {
            loadTOCApi.abort();
        }
    },
    setActivePage({commit, getters},{activeTab, tabPage}) {
        if( getters.getItem('activeTab') !='' && (activeTab != getters.getItem('activeTab') || tabPage != getters.getItem('tabPage'))) {
            let prevActiveTabs = getters.getItem('prevActiveTabs');
            prevActiveTabs.unshift({activeTab: getters.getItem('activeTab'), tabPage: getters.getItem('tabPage')});
            commit(Mutation.SET_ITEM, {field: 'prevActiveTabs', value: prevActiveTabs});
        }
        commit(Mutation.SET_ITEM, {field: 'activeTab', value: activeTab});
        commit(Mutation.SET_ITEM, {field: 'tabPage', value: tabPage});

    },
    setActivePageForCompareHistory: ({ commit, getters,dispatch },{activeTab, tabPage}) => {
        dispatch('setActivePage', {activeTab, tabPage});
        if(getters.getItem('newCompare')) {
            dispatch('resetCompareHistoryFilters');
            dispatch('loadCompareHistory');
            commit(Mutation.SET_ITEM, {field: 'newCompare', value: false});
        }
    },
    loadCompareHistory: async ({ commit, state }) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'compareHistoryLoading', value: true});
            const params = {
                docId: state.docId,
                offset: (state.compareHistoryPage - 1) * state.compareHistoryItemsPerPage,
                limit: state.compareHistoryItemsPerPage,
                sortBy: state.compareHistorySortBy || 'date',
                sortOrder: state.compareHistorySortDesc ? 'asc' : 'desc',
                startDate: state.compareHistoryDateRange[0],
                endDate: state.compareHistoryDateRange[1],
                priorVersion: state.compareHistoryPriorVersion ? state.compareHistoryPriorVersion : '',
                newerVersion: state.compareHistoryNewerVersion ? state.compareHistoryNewerVersion : '',
            };
            const result = await apiGetCompareHistory(params.docId, params.offset, params.limit, params.sortBy, params.sortOrder, params.startDate, params.endDate, params.priorVersion, params.newerVersion);

            if (!Array.isArray(result.data)) {
                console.error('Invalid API response format:', result.data);
                return;
            }
            const formattedHistory = result.data.map(item => ({
                id: item.id || 0,
                date: item.updated_at || '',
                version1: item.params.compareContent[0].itemName + ' | ' + item.params.compareContent[0].title || '',
                version2: item.params.compareContent[1].itemName + ' | ' + item.params.compareContent[1].title || '',
                rating: item.rating || -1,
                selectedPromptId: item.prompt_id || 0,
                diffSummary: item.result || '',
                params: item.params
            }));
            commit(Mutation.SET_ITEM, { field: 'compareHistory', value: formattedHistory });
            commit(Mutation.SET_ITEM, { field: 'compareHistoryTotal', value: result.total });
            commit(Mutation.SET_ITEM, {field: 'compareHistoryLoading', value: false});
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'compareHistoryLoading', value: false});
        }
    },
    deleteCompareHistory: async ({ commit, dispatch }, compareId) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'compareHistoryLoading', value: true});
            await apiDeleteCompareHistory(compareId);
            commit(Mutation.SET_ITEM, {field: 'compareHistoryLoading', value: false});
        } catch(error) {
            console.log(error);
            commit(Mutation.SET_ITEM, {field: 'compareHistoryLoading', value: false});
        }
        dispatch('loadCompareHistory');
    },
    updateQuotaUsed: async ({ commit }) => {
        try {
            let quotaUsed = await apiGetCompareQueryCount();
            quotaUsed = isNaN(parseInt(quotaUsed, 10)) ? 0 : parseInt(quotaUsed, 10);
            commit(Mutation.SET_ITEM, {field: 'quotaUsed', value: quotaUsed});
        } catch(error) {
            console.log('api call to get quota used failed');
            console.log(error);
        }
    },
    setTOCForCompare: ({ commit, getters }, {versionType, itemId, itemName}) => {
        let tocType = versionType == 'prior' ? '1' : '2';
        const language = getters.getItem('language');
        let itemsToCompare = getters.getItemsToCompare(language);
        for (let i = 0; i < itemsToCompare.length; i++) {
            if (itemsToCompare[i].item_id == itemId) {
                commit(Mutation.SET_ITEM, { field: 'toc' + tocType, value: JSON.parse(itemsToCompare[i].toc) });
                break; // Exit loop early once a match is found
            }
        }
        commit(Mutation.SET_ITEM, {field: 'itemId' + tocType, value: itemId});
        commit(Mutation.SET_ITEM, {field: 'itemName' + tocType, value: itemName});
    },
    resetCompareHistoryFilters: ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'compareHistorySortBy', value: 'date'});
        commit(Mutation.SET_ITEM, {field: 'compareHistorySortDesc', value: false});
        commit(Mutation.SET_ITEM, {field: 'compareHistoryDateRange', value: ['', '']});
        commit(Mutation.SET_ITEM, {field: 'compareHistoryPriorVersion', value: ''});
        commit(Mutation.SET_ITEM, {field: 'compareHistoryNewerVersion', value: ''});
        commit(Mutation.SET_ITEM, {field: 'compareHistorySortOrder', value: 'desc'});
        commit(Mutation.SET_ITEM, {field: 'compareHistoryPage', value: 1});
        commit(Mutation.SET_ITEM, {field: 'compareHistoryItemsPerPage', value: 10});
    }
}